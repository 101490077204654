<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState=='Update'">Update Programmesrondes #{{ formData.id }}</div>
        <div v-if="formState=='Create'">Create Programmesrondes</div>
      </template>

      <EditProgrammesrondes
          v-if="formState=='Update'"
          :modalFormId="formId"
          :key="formKey"
          :data="formData"
          :gridApi="formGridApi"
          @close="closeForm"
          :horairesData="horairesData"
          :postesData="postesData"
          :programmationsrondesData="programmationsrondesData"
          :programmationsusersData="programmationsusersData"
          :usersData="usersData"
      />


      <CreateProgrammesrondes
          v-if="formState=='Create'"
          :modalFormId="formId"
          :key="formKey"
          :gridApi="formGridApi"
          @close="closeForm"
          :horairesData="horairesData"
          :postesData="postesData"
          :programmationsrondesData="programmationsrondesData"
          :programmationsusersData="programmationsusersData"
          :usersData="usersData"
      />

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <AgGridTable
          :key="tableKey"
          domLayout='autoHeight'
          rowSelection="multiple"
          className="ag-theme-alpine"
          :columnDefs="columnDefs"
          :url="url"
          :rowModelType="rowModelType"
          :paginationPageSize="paginationPageSize"
          :cacheBlockSize="cacheBlockSize"
          :maxBlocksInCache="maxBlocksInCache"
          :pagination="pagination"
          :rowData="rowData"
          @gridReady="onGridReady"

      >
        <template #header_buttons>
          <div class="btn btn-primary" v-if="!routeData.meta.hideCreate" @click="openCreate"><i class="fa fa-plus"></i>
            Nouveau
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>

import CreateProgrammesrondes from './CreateProgrammesrondes.vue'
import EditProgrammesrondes from './EditProgrammesrondes.vue'
import CustomFiltre from "@/components/CustomFiltre.vue"


export default {
  name: 'ProgrammesrondesView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateProgrammesrondes,
    EditProgrammesrondes,
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomSelect: () => import("@/components/CustomSelect.vue"),
    CustomFiltre
  },
  data() {

    return {
      formId: "programmesrondes",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/programmesrondes-Aggrid',
      table: 'programmesrondes',
      horairesData: [],
      postesData: [],
      programmationsrondesData: [],
      programmationsusersData: [],
      usersData: [],
      requette: 5,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 100,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    routeData: function () {
      let router = {meta: {}}
      if (window.router) {
        try {
          router = window.router;
        } catch (e) {
        }
      }


      return router
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/programmesrondes-Aggrid',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
        [
          {
            field: null,
            headerName: '',
            suppressCellSelection: true,
            width: 80,
            pinned: 'left',
            cellRendererSelector: params => {
              return {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                    this.showForm('Update', field, params.api)
                  },
                  render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-raduis:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                }
              };
            },

          },


          {
            field: "date",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'date',
            valueFormatter: params => {
              let retour = params.value
              try {
                retour = params.value.split(' ')[0]
              } catch (e) {

              }
              return retour
            }
          },


          {
            field: "debut_prevu",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'debut_prevu',
          },


          {
            field: "fin_prevu",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'fin_prevu',
          },


          {
            field: "debut_reel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'debut_reel',
          },


          {
            field: "debut_realise",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'debut_realise',
          },


          {
            field: "fin_realise",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'fin_realise',
          },


          {
            field: "volume_horaire",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'volume_horaire',
          },


          {
            field: "hs_base",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'hs_base',
          },


          {
            field: "hs_hors_faction",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'hs_hors_faction',
          },


          {
            field: "hs_in_faction",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'hs_in_faction',
          },


          {
            field: "etats",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'etats',
          },


          {
            field: "totalReel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'totalReel',
          },


          {
            field: "totalFictif",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'totalFictif',
          },


          {
            field: "remplacant",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'remplacant',
          },


          {
            field: "type",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'type',
          },


          {
            field: "week",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'week',
          },


          {
            field: "user",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'user',
          },


          {
            field: "DayStatut",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'DayStatut',
          },


          {
            field: "Remplacantuser",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Remplacantuser',
          },


          {
            field: "PresencesDeclarer",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'PresencesDeclarer',
          },


          {
            field: "AbscencesDeclarer",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'AbscencesDeclarer',
          },


          {
            field: "EtatsDeclarer",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'EtatsDeclarer',
          },


          {
            field: "Totalpresent",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Totalpresent',
          },


          {
            field: "J1",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J1',
          },


          {
            field: "J2",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J2',
          },


          {
            field: "J3",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J3',
          },


          {
            field: "J4",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J4',
          },


          {
            field: "J5",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J5',
          },


          {
            field: "J6",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J6',
          },


          {
            field: "J7",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J7',
          },


          {
            field: "J8",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J8',
          },


          {
            field: "J9",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J9',
          },


          {
            field: "J10",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J10',
          },


          {
            field: "J11",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J11',
          },


          {
            field: "J12",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J12',
          },


          {
            field: "J13",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J13',
          },


          {
            field: "J14",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J14',
          },


          {
            field: "J15",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J15',
          },


          {
            field: "J16",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J16',
          },


          {
            field: "J17",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J17',
          },


          {
            field: "J18",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J18',
          },


          {
            field: "J19",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J19',
          },


          {
            field: "J20",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J20',
          },


          {
            field: "J21",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J21',
          },


          {
            field: "J22",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J22',
          },


          {
            field: "J23",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J23',
          },


          {
            field: "J24",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J24',
          },


          {
            field: "J25",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J25',
          },


          {
            field: "J26",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J26',
          },


          {
            field: "J27",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J27',
          },


          {
            field: "J28",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J28',
          },


          {
            field: "J29",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J29',
          },


          {
            field: "J30",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J30',
          },


          {
            field: "J31",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'J31',
          },


          {
            field: "deja_annaliser",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'deja_annaliser',
          },


          {
            field: "pointages_rattacher_auto",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_rattacher_auto',
          },


          {
            field: "pointages_rattacher_manuel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_rattacher_manuel',
          },


          {
            field: "pointages_debut_auto",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_debut_auto',
          },


          {
            field: "pointages_debut_manuel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_debut_manuel',
          },


          {
            field: "pointages_fin_auto",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_fin_auto',
          },


          {
            field: "pointages_fin_manuel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'pointages_fin_manuel',
          },


          {
            field: "presence_declarer_auto",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'presence_declarer_auto',
          },


          {
            field: "presence_declarer_manuel",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'presence_declarer_manuel',
          },


          {
            field: "creat_by",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'creat_by',
          },


          {
            headerName: 'horaire',
            field: 'horaire.Selectlabel',
          },
          {

            hide: true,
            suppressColumnsToolPanel: true,

            headerName: 'horaire',
            field: 'horaire_id',
            valueFormatter: params => {
              let retour = ''
              try {
                return params.data['horaire']['Selectlabel']
              } catch (e) {

              }
              return retour
            },

            filter: "CustomFiltre",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/horaires-Aggrid',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
          },


          {
            headerName: 'poste',
            field: 'poste.Selectlabel',
          },
          {

            hide: true,
            suppressColumnsToolPanel: true,

            headerName: 'poste',
            field: 'poste_id',
            valueFormatter: params => {
              let retour = ''
              try {
                return params.data['poste']['Selectlabel']
              } catch (e) {

              }
              return retour
            },

            filter: "CustomFiltre",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/postes-Aggrid',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
          },


          {
            headerName: 'programmationsronde',
            field: 'programmationsronde.Selectlabel',
          },
          {

            hide: true,
            suppressColumnsToolPanel: true,

            headerName: 'programmationsronde',
            field: 'programmationsronde_id',
            valueFormatter: params => {
              let retour = ''
              try {
                return params.data['programmationsronde']['Selectlabel']
              } catch (e) {

              }
              return retour
            },

            filter: "CustomFiltre",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/programmationsrondes-Aggrid',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
          },


          {
            headerName: 'programmationsuser',
            field: 'programmationsuser.Selectlabel',
          },
          {

            hide: true,
            suppressColumnsToolPanel: true,

            headerName: 'programmationsuser',
            field: 'programmationsuser_id',
            valueFormatter: params => {
              let retour = ''
              try {
                return params.data['programmationsuser']['Selectlabel']
              } catch (e) {

              }
              return retour
            },

            filter: "CustomFiltre",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/programmationsusers-Aggrid',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
          },


          {
            headerName: 'user',
            field: 'user.Selectlabel',
          },
          {

            hide: true,
            suppressColumnsToolPanel: true,

            headerName: 'user',
            field: 'user_id',
            valueFormatter: params => {
              let retour = ''
              try {
                return params.data['user']['Selectlabel']
              } catch (e) {

              }
              return retour
            },

            filter: "CustomFiltre",
            filterParams: {
              url: this.axios.defaults.baseURL + '/api/users-Aggrid',
              columnDefs: [
                {
                  field: "",
                  sortable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {suppressAndOrCondition: true},
                  headerName: "",
                  cellStyle: {fontSize: '11px'},
                  valueFormatter: (params) => {
                    let retour = "";
                    try {
                      return `${params.data["Selectlabel"]}`;
                    } catch (e) {
                    }
                    return retour;
                  },
                },
              ],
              filterFields: ['libelle'],
            },
          },

        ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }

    this.gethoraires();
    this.getpostes();
    this.getprogrammationsrondes();
    this.getprogrammationsusers();
    this.getusers();

  },
  methods: {
    closeForm() {
      this.tableKey++
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    gethoraires() {
      this.axios.get('/api/horaires').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.horairesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getpostes() {
      this.axios.get('/api/postes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.postesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getprogrammationsrondes() {
      this.axios.get('/api/programmationsrondes').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.programmationsrondesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getprogrammationsusers() {
      this.axios.get('/api/programmationsusers').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.programmationsusersData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getusers() {
      this.axios.get('/api/users').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // this.$store.commit('setIsLoading', false)
        }
        this.usersData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

  }
}
</script>
