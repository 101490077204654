<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>date </label>
          <input type="date" :class="errors.date?'form-control is-invalid':'form-control'"
                 v-model="form.date">

          <div class="invalid-feedback" v-if="errors.date">
            <template v-for=" error in errors.date"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>debut_prevu </label>
          <input type="text" :class="errors.debut_prevu?'form-control is-invalid':'form-control'"
                 v-model="form.debut_prevu">

          <div class="invalid-feedback" v-if="errors.debut_prevu">
            <template v-for=" error in errors.debut_prevu"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>fin_prevu </label>
          <input type="text" :class="errors.fin_prevu?'form-control is-invalid':'form-control'"
                 v-model="form.fin_prevu">

          <div class="invalid-feedback" v-if="errors.fin_prevu">
            <template v-for=" error in errors.fin_prevu"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>debut_reel </label>
          <input type="text" :class="errors.debut_reel?'form-control is-invalid':'form-control'"
                 v-model="form.debut_reel">

          <div class="invalid-feedback" v-if="errors.debut_reel">
            <template v-for=" error in errors.debut_reel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>debut_realise </label>
          <input type="text" :class="errors.debut_realise?'form-control is-invalid':'form-control'"
                 v-model="form.debut_realise">

          <div class="invalid-feedback" v-if="errors.debut_realise">
            <template v-for=" error in errors.debut_realise"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>fin_realise </label>
          <input type="text" :class="errors.fin_realise?'form-control is-invalid':'form-control'"
                 v-model="form.fin_realise">

          <div class="invalid-feedback" v-if="errors.fin_realise">
            <template v-for=" error in errors.fin_realise"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>volume_horaire </label>
          <input type="text" :class="errors.volume_horaire?'form-control is-invalid':'form-control'"
                 v-model="form.volume_horaire">

          <div class="invalid-feedback" v-if="errors.volume_horaire">
            <template v-for=" error in errors.volume_horaire"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>hs_base </label>
          <input type="text" :class="errors.hs_base?'form-control is-invalid':'form-control'"
                 v-model="form.hs_base">

          <div class="invalid-feedback" v-if="errors.hs_base">
            <template v-for=" error in errors.hs_base"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>hs_hors_faction </label>
          <input type="text" :class="errors.hs_hors_faction?'form-control is-invalid':'form-control'"
                 v-model="form.hs_hors_faction">

          <div class="invalid-feedback" v-if="errors.hs_hors_faction">
            <template v-for=" error in errors.hs_hors_faction"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>hs_in_faction </label>
          <input type="text" :class="errors.hs_in_faction?'form-control is-invalid':'form-control'"
                 v-model="form.hs_in_faction">

          <div class="invalid-feedback" v-if="errors.hs_in_faction">
            <template v-for=" error in errors.hs_in_faction"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>etats </label>
          <input type="text" :class="errors.etats?'form-control is-invalid':'form-control'"
                 v-model="form.etats">

          <div class="invalid-feedback" v-if="errors.etats">
            <template v-for=" error in errors.etats"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>totalReel </label>
          <input type="text" :class="errors.totalReel?'form-control is-invalid':'form-control'"
                 v-model="form.totalReel">

          <div class="invalid-feedback" v-if="errors.totalReel">
            <template v-for=" error in errors.totalReel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>totalFictif </label>
          <input type="text" :class="errors.totalFictif?'form-control is-invalid':'form-control'"
                 v-model="form.totalFictif">

          <div class="invalid-feedback" v-if="errors.totalFictif">
            <template v-for=" error in errors.totalFictif"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>remplacant </label>
          <input type="text" :class="errors.remplacant?'form-control is-invalid':'form-control'"
                 v-model="form.remplacant">

          <div class="invalid-feedback" v-if="errors.remplacant">
            <template v-for=" error in errors.remplacant"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>type </label>
          <input type="text" :class="errors.type?'form-control is-invalid':'form-control'"
                 v-model="form.type">

          <div class="invalid-feedback" v-if="errors.type">
            <template v-for=" error in errors.type"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>week </label>
          <input type="text" :class="errors.week?'form-control is-invalid':'form-control'"
                 v-model="form.week">

          <div class="invalid-feedback" v-if="errors.week">
            <template v-for=" error in errors.week"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>user </label>
          <input type="text" :class="errors.user?'form-control is-invalid':'form-control'"
                 v-model="form.user">

          <div class="invalid-feedback" v-if="errors.user">
            <template v-for=" error in errors.user"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>DayStatut </label>
          <input type="text" :class="errors.DayStatut?'form-control is-invalid':'form-control'"
                 v-model="form.DayStatut">

          <div class="invalid-feedback" v-if="errors.DayStatut">
            <template v-for=" error in errors.DayStatut"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>Remplacantuser </label>
          <input type="text" :class="errors.Remplacantuser?'form-control is-invalid':'form-control'"
                 v-model="form.Remplacantuser">

          <div class="invalid-feedback" v-if="errors.Remplacantuser">
            <template v-for=" error in errors.Remplacantuser"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>PresencesDeclarer </label>
          <input type="text" :class="errors.PresencesDeclarer?'form-control is-invalid':'form-control'"
                 v-model="form.PresencesDeclarer">

          <div class="invalid-feedback" v-if="errors.PresencesDeclarer">
            <template v-for=" error in errors.PresencesDeclarer"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>AbscencesDeclarer </label>
          <input type="text" :class="errors.AbscencesDeclarer?'form-control is-invalid':'form-control'"
                 v-model="form.AbscencesDeclarer">

          <div class="invalid-feedback" v-if="errors.AbscencesDeclarer">
            <template v-for=" error in errors.AbscencesDeclarer"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>EtatsDeclarer </label>
          <input type="text" :class="errors.EtatsDeclarer?'form-control is-invalid':'form-control'"
                 v-model="form.EtatsDeclarer">

          <div class="invalid-feedback" v-if="errors.EtatsDeclarer">
            <template v-for=" error in errors.EtatsDeclarer"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>Totalpresent </label>
          <input type="text" :class="errors.Totalpresent?'form-control is-invalid':'form-control'"
                 v-model="form.Totalpresent">

          <div class="invalid-feedback" v-if="errors.Totalpresent">
            <template v-for=" error in errors.Totalpresent"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J1 </label>
          <input type="text" :class="errors.J1?'form-control is-invalid':'form-control'"
                 v-model="form.J1">

          <div class="invalid-feedback" v-if="errors.J1">
            <template v-for=" error in errors.J1"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J2 </label>
          <input type="text" :class="errors.J2?'form-control is-invalid':'form-control'"
                 v-model="form.J2">

          <div class="invalid-feedback" v-if="errors.J2">
            <template v-for=" error in errors.J2"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J3 </label>
          <input type="text" :class="errors.J3?'form-control is-invalid':'form-control'"
                 v-model="form.J3">

          <div class="invalid-feedback" v-if="errors.J3">
            <template v-for=" error in errors.J3"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J4 </label>
          <input type="text" :class="errors.J4?'form-control is-invalid':'form-control'"
                 v-model="form.J4">

          <div class="invalid-feedback" v-if="errors.J4">
            <template v-for=" error in errors.J4"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J5 </label>
          <input type="text" :class="errors.J5?'form-control is-invalid':'form-control'"
                 v-model="form.J5">

          <div class="invalid-feedback" v-if="errors.J5">
            <template v-for=" error in errors.J5"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J6 </label>
          <input type="text" :class="errors.J6?'form-control is-invalid':'form-control'"
                 v-model="form.J6">

          <div class="invalid-feedback" v-if="errors.J6">
            <template v-for=" error in errors.J6"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J7 </label>
          <input type="text" :class="errors.J7?'form-control is-invalid':'form-control'"
                 v-model="form.J7">

          <div class="invalid-feedback" v-if="errors.J7">
            <template v-for=" error in errors.J7"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J8 </label>
          <input type="text" :class="errors.J8?'form-control is-invalid':'form-control'"
                 v-model="form.J8">

          <div class="invalid-feedback" v-if="errors.J8">
            <template v-for=" error in errors.J8"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J9 </label>
          <input type="text" :class="errors.J9?'form-control is-invalid':'form-control'"
                 v-model="form.J9">

          <div class="invalid-feedback" v-if="errors.J9">
            <template v-for=" error in errors.J9"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J10 </label>
          <input type="text" :class="errors.J10?'form-control is-invalid':'form-control'"
                 v-model="form.J10">

          <div class="invalid-feedback" v-if="errors.J10">
            <template v-for=" error in errors.J10"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J11 </label>
          <input type="text" :class="errors.J11?'form-control is-invalid':'form-control'"
                 v-model="form.J11">

          <div class="invalid-feedback" v-if="errors.J11">
            <template v-for=" error in errors.J11"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J12 </label>
          <input type="text" :class="errors.J12?'form-control is-invalid':'form-control'"
                 v-model="form.J12">

          <div class="invalid-feedback" v-if="errors.J12">
            <template v-for=" error in errors.J12"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J13 </label>
          <input type="text" :class="errors.J13?'form-control is-invalid':'form-control'"
                 v-model="form.J13">

          <div class="invalid-feedback" v-if="errors.J13">
            <template v-for=" error in errors.J13"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J14 </label>
          <input type="text" :class="errors.J14?'form-control is-invalid':'form-control'"
                 v-model="form.J14">

          <div class="invalid-feedback" v-if="errors.J14">
            <template v-for=" error in errors.J14"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J15 </label>
          <input type="text" :class="errors.J15?'form-control is-invalid':'form-control'"
                 v-model="form.J15">

          <div class="invalid-feedback" v-if="errors.J15">
            <template v-for=" error in errors.J15"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J16 </label>
          <input type="text" :class="errors.J16?'form-control is-invalid':'form-control'"
                 v-model="form.J16">

          <div class="invalid-feedback" v-if="errors.J16">
            <template v-for=" error in errors.J16"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J17 </label>
          <input type="text" :class="errors.J17?'form-control is-invalid':'form-control'"
                 v-model="form.J17">

          <div class="invalid-feedback" v-if="errors.J17">
            <template v-for=" error in errors.J17"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J18 </label>
          <input type="text" :class="errors.J18?'form-control is-invalid':'form-control'"
                 v-model="form.J18">

          <div class="invalid-feedback" v-if="errors.J18">
            <template v-for=" error in errors.J18"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J19 </label>
          <input type="text" :class="errors.J19?'form-control is-invalid':'form-control'"
                 v-model="form.J19">

          <div class="invalid-feedback" v-if="errors.J19">
            <template v-for=" error in errors.J19"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J20 </label>
          <input type="text" :class="errors.J20?'form-control is-invalid':'form-control'"
                 v-model="form.J20">

          <div class="invalid-feedback" v-if="errors.J20">
            <template v-for=" error in errors.J20"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J21 </label>
          <input type="text" :class="errors.J21?'form-control is-invalid':'form-control'"
                 v-model="form.J21">

          <div class="invalid-feedback" v-if="errors.J21">
            <template v-for=" error in errors.J21"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J22 </label>
          <input type="text" :class="errors.J22?'form-control is-invalid':'form-control'"
                 v-model="form.J22">

          <div class="invalid-feedback" v-if="errors.J22">
            <template v-for=" error in errors.J22"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J23 </label>
          <input type="text" :class="errors.J23?'form-control is-invalid':'form-control'"
                 v-model="form.J23">

          <div class="invalid-feedback" v-if="errors.J23">
            <template v-for=" error in errors.J23"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J24 </label>
          <input type="text" :class="errors.J24?'form-control is-invalid':'form-control'"
                 v-model="form.J24">

          <div class="invalid-feedback" v-if="errors.J24">
            <template v-for=" error in errors.J24"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J25 </label>
          <input type="text" :class="errors.J25?'form-control is-invalid':'form-control'"
                 v-model="form.J25">

          <div class="invalid-feedback" v-if="errors.J25">
            <template v-for=" error in errors.J25"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J26 </label>
          <input type="text" :class="errors.J26?'form-control is-invalid':'form-control'"
                 v-model="form.J26">

          <div class="invalid-feedback" v-if="errors.J26">
            <template v-for=" error in errors.J26"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J27 </label>
          <input type="text" :class="errors.J27?'form-control is-invalid':'form-control'"
                 v-model="form.J27">

          <div class="invalid-feedback" v-if="errors.J27">
            <template v-for=" error in errors.J27"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J28 </label>
          <input type="text" :class="errors.J28?'form-control is-invalid':'form-control'"
                 v-model="form.J28">

          <div class="invalid-feedback" v-if="errors.J28">
            <template v-for=" error in errors.J28"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J29 </label>
          <input type="text" :class="errors.J29?'form-control is-invalid':'form-control'"
                 v-model="form.J29">

          <div class="invalid-feedback" v-if="errors.J29">
            <template v-for=" error in errors.J29"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J30 </label>
          <input type="text" :class="errors.J30?'form-control is-invalid':'form-control'"
                 v-model="form.J30">

          <div class="invalid-feedback" v-if="errors.J30">
            <template v-for=" error in errors.J30"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>J31 </label>
          <input type="text" :class="errors.J31?'form-control is-invalid':'form-control'"
                 v-model="form.J31">

          <div class="invalid-feedback" v-if="errors.J31">
            <template v-for=" error in errors.J31"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>deja_annaliser </label>
          <input type="text" :class="errors.deja_annaliser?'form-control is-invalid':'form-control'"
                 v-model="form.deja_annaliser">

          <div class="invalid-feedback" v-if="errors.deja_annaliser">
            <template v-for=" error in errors.deja_annaliser"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_rattacher_auto </label>
          <input type="text" :class="errors.pointages_rattacher_auto?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_rattacher_auto">

          <div class="invalid-feedback" v-if="errors.pointages_rattacher_auto">
            <template v-for=" error in errors.pointages_rattacher_auto"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_rattacher_manuel </label>
          <input type="text" :class="errors.pointages_rattacher_manuel?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_rattacher_manuel">

          <div class="invalid-feedback" v-if="errors.pointages_rattacher_manuel">
            <template v-for=" error in errors.pointages_rattacher_manuel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_debut_auto </label>
          <input type="text" :class="errors.pointages_debut_auto?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_debut_auto">

          <div class="invalid-feedback" v-if="errors.pointages_debut_auto">
            <template v-for=" error in errors.pointages_debut_auto"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_debut_manuel </label>
          <input type="text" :class="errors.pointages_debut_manuel?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_debut_manuel">

          <div class="invalid-feedback" v-if="errors.pointages_debut_manuel">
            <template v-for=" error in errors.pointages_debut_manuel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_fin_auto </label>
          <input type="text" :class="errors.pointages_fin_auto?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_fin_auto">

          <div class="invalid-feedback" v-if="errors.pointages_fin_auto">
            <template v-for=" error in errors.pointages_fin_auto"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>pointages_fin_manuel </label>
          <input type="text" :class="errors.pointages_fin_manuel?'form-control is-invalid':'form-control'"
                 v-model="form.pointages_fin_manuel">

          <div class="invalid-feedback" v-if="errors.pointages_fin_manuel">
            <template v-for=" error in errors.pointages_fin_manuel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>presence_declarer_auto </label>
          <input type="text" :class="errors.presence_declarer_auto?'form-control is-invalid':'form-control'"
                 v-model="form.presence_declarer_auto">

          <div class="invalid-feedback" v-if="errors.presence_declarer_auto">
            <template v-for=" error in errors.presence_declarer_auto"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>presence_declarer_manuel </label>
          <input type="text" :class="errors.presence_declarer_manuel?'form-control is-invalid':'form-control'"
                 v-model="form.presence_declarer_manuel">

          <div class="invalid-feedback" v-if="errors.presence_declarer_manuel">
            <template v-for=" error in errors.presence_declarer_manuel"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>horaires </label>
          <CustomSelect
              :key="form.horaire"
              :url="`${axios.defaults.baseURL}/api/horaires-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.horaire"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.horaire_id=data.id;form.horaire=data}"
          />
          <div class="invalid-feedback" v-if="errors.horaire_id">
            <template v-for=" error in errors.horaire_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>postes </label>
          <CustomSelect
              :key="form.poste"
              :url="`${axios.defaults.baseURL}/api/postes-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.poste"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.poste_id=data.id;form.poste=data}"
          />
          <div class="invalid-feedback" v-if="errors.poste_id">
            <template v-for=" error in errors.poste_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>programmationsrondes </label>
          <CustomSelect
              :key="form.programmationsronde"
              :url="`${axios.defaults.baseURL}/api/programmationsrondes-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.programmationsronde"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.programmationsronde_id=data.id;form.programmationsronde=data}"
          />
          <div class="invalid-feedback" v-if="errors.programmationsronde_id">
            <template v-for=" error in errors.programmationsronde_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>programmationsusers </label>
          <CustomSelect
              :key="form.programmationsuser"
              :url="`${axios.defaults.baseURL}/api/programmationsusers-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.programmationsuser"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.programmationsuser_id=data.id;form.programmationsuser=data}"
          />
          <div class="invalid-feedback" v-if="errors.programmationsuser_id">
            <template v-for=" error in errors.programmationsuser_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>users </label>
          <CustomSelect
              :key="form.user"
              :url="`${axios.defaults.baseURL}/api/users-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.user"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.user_id=data.id;form.user=data}"
          />
          <div class="invalid-feedback" v-if="errors.user_id">
            <template v-for=" error in errors.user_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateProgrammesrondes',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'horairesData',
    'postesData',
    'programmationsrondesData',
    'programmationsusersData',
    'usersData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        date: "",

        debut_prevu: "",

        fin_prevu: "",

        debut_reel: "",

        debut_realise: "",

        fin_realise: "",

        volume_horaire: "",

        hs_base: "",

        hs_hors_faction: "",

        hs_in_faction: "",

        programmationsuser_id: "",

        horaire_id: "",

        etats: "",

        totalReel: "",

        totalFictif: "",

        poste_id: "",

        remplacant: "",

        type: "",

        week: "",

        user: "",

        DayStatut: "",

        Remplacantuser: "",

        PresencesDeclarer: "",

        AbscencesDeclarer: "",

        EtatsDeclarer: "",

        Totalpresent: "",

        J1: "",

        J2: "",

        J3: "",

        J4: "",

        J5: "",

        J6: "",

        J7: "",

        J8: "",

        J9: "",

        J10: "",

        J11: "",

        J12: "",

        J13: "",

        J14: "",

        J15: "",

        J16: "",

        J17: "",

        J18: "",

        J19: "",

        J20: "",

        J21: "",

        J22: "",

        J23: "",

        J24: "",

        J25: "",

        J26: "",

        J27: "",

        J28: "",

        J29: "",

        J30: "",

        J31: "",

        deja_annaliser: "",

        pointages_rattacher_auto: "",

        pointages_rattacher_manuel: "",

        pointages_debut_auto: "",

        pointages_debut_manuel: "",

        pointages_fin_auto: "",

        pointages_fin_manuel: "",

        presence_declarer_auto: "",

        presence_declarer_manuel: "",

        programmationsronde_id: "",

        user_id: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/programmesrondes', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        date: "",
        debut_prevu: "",
        fin_prevu: "",
        debut_reel: "",
        debut_realise: "",
        fin_realise: "",
        volume_horaire: "",
        hs_base: "",
        hs_hors_faction: "",
        hs_in_faction: "",
        programmationsuser_id: "",
        horaire_id: "",
        etats: "",
        totalReel: "",
        totalFictif: "",
        poste_id: "",
        remplacant: "",
        type: "",
        week: "",
        user: "",
        DayStatut: "",
        Remplacantuser: "",
        PresencesDeclarer: "",
        AbscencesDeclarer: "",
        EtatsDeclarer: "",
        Totalpresent: "",
        J1: "",
        J2: "",
        J3: "",
        J4: "",
        J5: "",
        J6: "",
        J7: "",
        J8: "",
        J9: "",
        J10: "",
        J11: "",
        J12: "",
        J13: "",
        J14: "",
        J15: "",
        J16: "",
        J17: "",
        J18: "",
        J19: "",
        J20: "",
        J21: "",
        J22: "",
        J23: "",
        J24: "",
        J25: "",
        J26: "",
        J27: "",
        J28: "",
        J29: "",
        J30: "",
        J31: "",
        deja_annaliser: "",
        pointages_rattacher_auto: "",
        pointages_rattacher_manuel: "",
        pointages_debut_auto: "",
        pointages_debut_manuel: "",
        pointages_fin_auto: "",
        pointages_fin_manuel: "",
        presence_declarer_auto: "",
        presence_declarer_manuel: "",
        programmationsronde_id: "",
        user_id: "",
        creat_by: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      }
    }
  }
}
</script>
